function markSerializable<FN extends (...args: any[]) => any>(fn: FN): FN {
	(fn as any).__qwik_serializable__ = true;
	return fn;
}

export const image = {
	name: 'image',
	type: 'object',
	subFields: [
		{
			name: 'src',
			type: 'file',
			bubble: true,
			required: true,
			defaultValue:
				'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
			onChange: markSerializable((options: Map<string, any>) => {
				const DEFAULT_ASPECT_RATIO = 0.7041;
				options.delete('srcset');
				options.delete('noWebp');
				function loadImage(url: string, timeout = 60000): Promise<HTMLImageElement> {
					return new Promise((resolve, reject) => {
						const img = document.createElement('img');
						let loaded = false;
						img.onload = () => {
							loaded = true;
							resolve(img);
						};

						img.addEventListener('error', (event) => {
							console.warn('Image load failed', event.error);
							reject(event.error);
						});

						img.src = url;
						setTimeout(() => {
							if (!loaded) {
								reject(new Error('Image load timed out'));
							}
						}, timeout);
					});
				}

				function round(num: number) {
					return Math.round(num * 1000) / 1000;
				}

				const value = options.get('image');
				const aspectRatio = options.get('aspectRatio');

				// For SVG images - don't render as webp, keep them as SVG
				fetch(value)
					.then((res) => res.blob())
					.then((blob) => {
						if (blob.type.includes('svg')) {
							options.set('noWebp', true);
						}
					});

				if (value && (!aspectRatio || aspectRatio === DEFAULT_ASPECT_RATIO)) {
					return loadImage(value).then((img) => {
						const possiblyUpdatedAspectRatio = options.get('aspectRatio');
						if (
							options.get('image') === value &&
							(!possiblyUpdatedAspectRatio || possiblyUpdatedAspectRatio === DEFAULT_ASPECT_RATIO)
						) {
							if (img.width && img.height) {
								options.set('aspectRatio', round(img.height / img.width));
								options.set('height', img.height);
								options.set('width', img.width);
							}
						}
					});
				}
			}),
		},
		{
			name: 'alt',
			type: 'string',
			required: true,
		},
	],
};

export const imageNotRequired = {
	name: 'image',
	type: 'object',
	subFields: [
		{
			name: 'src',
			type: 'file',
			bubble: true,
			required: false,
			defaultValue:
				'https://cdn.builder.io/api/v1/image/assets%2Fpwgjf0RoYWbdnJSbpBAjXNRMe9F2%2Ffb27a7c790324294af8be1c35fe30f4d',
			onChange: markSerializable((options: Map<string, any>) => {
				const DEFAULT_ASPECT_RATIO = 0.7041;
				options.delete('srcset');
				options.delete('noWebp');
				function loadImage(url: string, timeout = 60000): Promise<HTMLImageElement> {
					return new Promise((resolve, reject) => {
						const img = document.createElement('img');
						let loaded = false;
						img.onload = () => {
							loaded = true;
							resolve(img);
						};

						img.addEventListener('error', (event) => {
							console.warn('Image load failed', event.error);
							reject(event.error);
						});

						img.src = url;
						setTimeout(() => {
							if (!loaded) {
								reject(new Error('Image load timed out'));
							}
						}, timeout);
					});
				}

				function round(num: number) {
					return Math.round(num * 1000) / 1000;
				}

				const value = options.get('image');
				const aspectRatio = options.get('aspectRatio');

				// For SVG images - don't render as webp, keep them as SVG
				fetch(value)
					.then((res) => res.blob())
					.then((blob) => {
						if (blob.type.includes('svg')) {
							options.set('noWebp', true);
						}
					});

				if (value && (!aspectRatio || aspectRatio === DEFAULT_ASPECT_RATIO)) {
					return loadImage(value).then((img) => {
						const possiblyUpdatedAspectRatio = options.get('aspectRatio');
						if (
							options.get('image') === value &&
							(!possiblyUpdatedAspectRatio || possiblyUpdatedAspectRatio === DEFAULT_ASPECT_RATIO)
						) {
							if (img.width && img.height) {
								options.set('aspectRatio', round(img.height / img.width));
								options.set('height', img.height);
								options.set('width', img.width);
							}
						}
					});
				}
			}),
		},
		{
			name: 'alt',
			type: 'string',
			required: false,
		},
	],
};

export const icon = {
	name: 'icon',
	type: 'object',
	subFields: [
		{
			name: 'src',
			type: 'file',
			required: true,
		},
		{
			name: 'alt',
			type: 'string',
			required: true,
		},
	],
};

export const button = {
	name: 'button',
	type: 'object',
	subFields: [
		{
			name: 'label',
			helperText: 'The text that will appear on the button',
			type: 'string',
			required: false,
		},
		{
			name: 'title',
			helperText: 'The title of the button, used for accessibility',
			type: 'string',
			required: false,
		},
		{
			name: 'url',
			type: 'url',
			required: false,
		},
		{
			name: 'target',
			type: 'boolean',
			defaultValue: false,
			helperText: 'Toggle to open internal link in a new tab. External links will always open in a new tab.',
		},
	],
};

export const pullQuote = {
	name: 'pullQuote',
	type: 'object',
	subFields: [
		{
			name: 'copy',
			type: 'longText',
		},
		button,
	],
};

export const link = {
	name: 'link',
	type: 'object',
	subFields: [
		{
			name: 'title',
			helperText: 'The title of the link, used for accessibility',
			type: 'string',
			required: false,
		},
		{
			name: 'url',
			type: 'url',
			required: false,
		},
		{
			name: 'target',
			type: 'boolean',
			defaultValue: false,
			helperText: 'Toggle to open internal link in a new tab. External links will always open in a new tab.',
		},
	],
};

export const anchorId = {
	name: 'anchorId',
	type: 'string',
	required: false,
};

export const padding = {
	name: 'padding',
	type: 'string',
	defaultValue: 'default',
	enum: [
		{ label: 'Default', value: 'default' },
		{ label: 'Half top', value: 'half-top' },
		{ label: 'Half bottom', value: 'half-bottom' },
		{ label: 'Half top and bottom', value: 'half' },
		{ label: 'None top', value: 'no-top' },
		{ label: 'None bottom', value: 'no-bottom' },
		{ label: 'None', value: 'none' },
	],
};
