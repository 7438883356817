// Builder
// https://github.com/builderio/builder/blob/ee8e6f2d/packages/core/src/builder.class.ts#L757
import { Builder, builder } from '@builder.io/sdk';

// Reusable input objects
import * as inputs from '@/composables/builder/inputs';

// Block categories tabs
const categories = {
	content: 'Content Blocks',
	media: 'Media Blocks',
	hero: 'Hero Blocks',
	cta: 'CTA Blocks',
	dynamic: 'Dynamic Blocks',
	child: 'Child Blocks',
};

export const getComponents = (api: string) => {
	// All options
	// https://github.com/builderio/builder/blob/ee8e6f2d/packages/core/src/builder.class.ts#L641
	// NOTE:
	// The category is used to group the blocks in the custom insert menu
	// This is a custom implementation and not part of the Builder.io API

	const newsletterBlock = {
		category: categories.dynamic,
		component: resolveComponent('newsletterBlock'),
		name: 'Newsletter',
		friendlyName: 'Newsletter',
		image:
			'https://cdn.builder.io/api/v1/image/assets%2Fe61abde79d954ddab2b6092044c4b5a1%2F0873d3a7adc84a059860b642f02c6ad2',
		screenshot:
			'https://cdn.builder.io/api/v1/image/assets%2Fe61abde79d954ddab2b6092044c4b5a1%2F0873d3a7adc84a059860b642f02c6ad2',
		inputs: [
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'copy',
				type: 'richText',
			},
			inputs.image,
			{
				name: 'action',
				type: 'string',
				required: true,
				defaultValue: 'subscribe',
				enum: [
					{ label: 'Subscribe', value: 'subscribe' },
					{ label: 'Update Preferences', value: 'update' },
				],
			},
			{
				name: 'thankYou',
				friendlyName: 'Thank You Message',
				type: 'object',
				subFields: [
					{
						name: 'headline',
						type: 'string',
					},
					{
						name: 'message',
						type: 'richText',
					},
				],
			},
			{
				name: 'privacyPolicy',
				friendlyName: 'Privacy Policy Link',
				type: 'url',
				required: true,
			},
		],
	};

	const calendarGridWithFilters = {
		category: categories.dynamic,
		component: resolveComponent('calendarGridWithFilters'),
		name: 'Calendar Grid With Filters',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/calendar-grid-with-filters.png',
		inputs: [],
	};

	const largeIconsBlock = {
		category: categories.content,
		component: resolveComponent('largeIconsBlock'),
		name: 'Large Icons Block',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/large-icons-block.png',
		inputs: [
			inputs.anchorId,
			{
				name: 'headlineArea',
				type: 'object',
				subFields: [
					{
						name: 'headline',
						type: 'string',
						required: false,
					},
					{
						name: 'body',
						type: 'richText',
						required: false,
					},
					inputs.icon,
				],
			},
			{
				name: 'iconsArea',
				type: 'object',
				subFields: [
					{
						name: 'subHeadline',
						type: 'string',
						required: false,
					},
					{
						name: 'icons',
						type: 'list',
						required: false,
						subFields: [
							{
								name: 'title',
								type: 'string',
								required: false,
							},
							{
								name: 'body',
								type: 'richText',
								required: false,
							},
							inputs.image,
						],
					},
				],
			},
		],
	};

	const storiesGrid = {
		category: categories.dynamic,
		component: resolveComponent('storiesGrid'),
		name: 'Stories Grid',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		// screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/additional-exhibitions.png',
		inputs: [
			inputs.anchorId,
			{
				name: 'headline',
				type: 'string',
				required: false,
				helperText: 'This block will automatically pull in Stories, but you can optionally add a headline.',
			},
		],
	};

	const alignedFiftyFifty = {
		category: categories.content,
		component: resolveComponent('alignedFiftyFifty'),
		name: 'Aligned 50 50',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/aligned-fifty-fifty.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			inputs.image,
			{
				name: 'imageAlignment',
				type: 'boolean',
				defaultValue: false,
				required: false,
				helperText: 'Switch this ON to align the image to the right. Leave it OFF to align the image to the left.',
			},
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 25',
			},
			{
				name: 'body',
				type: 'richText',
				helperText: 'Character limit: 160',
			},
			inputs.button,
		],
	};

	const docentProcess = {
		category: categories.content,
		component: resolveComponent('docentProcess'),
		name: 'Docent Process',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/member-benefits.png',
		inputs: [
			inputs.anchorId,
			inputs.image,
			{
				name: 'imageAlignment',
				type: 'boolean',
				defaultValue: false,
				required: false,
				helperText: 'Switch this ON to align the image to the right. Leave it OFF to align the image to the left.',
			},
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 30',
				required: false,
			},
			{
				name: 'body',
				type: 'richText',
				required: false,
			},
			inputs.button,
		],
	};

	const announcementBlock = {
		category: categories.dynamic,
		component: resolveComponent('announcement'),
		name: 'Auto Announcement',
		image:
			'https://cdn.builder.io/api/v1/image/assets%2F49af4dfb0fbc43d58bf8ce407e839d74%2F13acc1428b90430284cf7cfbd67e46cf',
		screenshot:
			'https://cdn.builder.io/api/v1/image/assets%2F49af4dfb0fbc43d58bf8ce407e839d74%2F13acc1428b90430284cf7cfbd67e46cf',
		inputs: [
			{
				name: 'headline',
				type: 'string',
				required: false,
				defaultValue: 'Announcement',
			},
			{
				name: 'backgroundColor',
				type: 'string',
				required: false,
				defaultValue: 'bg-white',
				enum: [
					{ label: 'White', value: 'bg-white' },
					{ label: 'Gray', value: 'bg-gray-100' },
					{ label: 'Red', value: 'bg-red' },
				],
			},
		],
	};

	const membershipOptions = {
		category: categories.content,
		component: resolveComponent('membershipOptions'),
		name: 'Membership Options',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/membership-options.png',
		inputs: [
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'defaultLevel',
				type: 'reference',
				model: 'membership-levels',
				helperText: 'Choose default membership level.',
			},
			{
				name: 'disclaimer',
				type: 'richText',
				helperText: 'If needed, add any disclaimer rich text to appear at the bottom of the block.',
			},
		],
	};

	const memberBenefits = {
		category: categories.content,
		component: resolveComponent('memberBenefits'),
		name: 'Member Benefits',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/member-benefits.png',
		inputs: [
			inputs.anchorId,
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'redBlock',
				type: 'list',
				required: false,
				subFields: [
					{
						name: 'icon',
						type: 'file',
						required: false,
					},
					{
						name: 'title',
						type: 'string',
						required: false,
					},
					{
						name: 'body',
						type: 'richText',
						required: false,
					},
				],
			},
			{
				name: 'whiteBlock',
				type: 'richText',
				required: false,
			},
		],
	};

	const teamMembersFilterGrid = {
		category: categories.dynamic,
		component: resolveComponent('teamMembersFilterGrid'),
		name: 'Team Members Filter Grid',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/team-members-filter-grid.png',
		inputs: [
			inputs.anchorId,
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'automaticallyFetchTeamMembers',
				type: 'boolean',
				defaultValue: true,
				helperText: 'Toggle this OFF if you prefer to manually choose Team Members below.',
			},
			{
				name: 'list',
				type: 'list',
				required: false,
				helperText: 'Choose the order of the Team Members.',
				subFields: [
					{
						name: 'teamMember',
						type: 'reference',
						model: 'team-members',
					},
				],
			},
		],
	};

	const relatedTeamMembers = {
		category: categories.dynamic,
		component: resolveComponent('relatedTeamMembers'),
		name: 'Related Team Members',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/related-team-members.png',
		inputs: [
			inputs.anchorId,
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			inputs.button,
			{
				name: 'automaticallyFetchTeamMembers',
				type: 'boolean',
				defaultValue: true,
				helperText: 'Toggle this OFF if you prefer to manually choose Team Members below.',
			},
			{
				name: 'list',
				type: 'list',
				required: false,
				helperText: 'Choose up to FOUR Team Members.',
				subFields: [
					{
						name: 'teamMember',
						type: 'reference',
						model: 'team-members',
					},
				],
			},
		],
	};

	const sponsorsMediaPartners = {
		category: categories.content,
		component: resolveComponent('sponsorsMediaPartners'),
		name: 'Sponsors and Media Partners',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/sponsors-media-partners.png',
		inputs: [
			inputs.anchorId,
			{
				name: 'rowList',
				type: 'list',
				required: false,
				subFields: [
					{
						name: 'rowTitle',
						type: 'string',
						required: false,
					},
					{
						name: 'cardList',
						type: 'list',
						required: false,
						helperText: 'LIMIT to FOUR cards.',
						subFields: [
							{
								name: 'logo',
								type: 'file',
								required: false,
								helperText:
									'IF a logo is available, upload here. OTHERWISE, the rectangle will default to the name input below.',
							},
							{
								name: 'sponsorName',
								type: 'string',
								required: true,
								helperText: 'The sponsor or media partner name is required.',
							},
							inputs.link,
						],
					},
				],
			},
			inputs.padding,
		],
	};

	const contactUsBlock = {
		category: categories.content,
		component: resolveComponent('contactUsBlock'),
		name: 'Contact Us Block',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/contact-us-block.png',
		inputs: [
			inputs.anchorId,
			{
				name: 'formDetails',
				type: 'object',
				required: false,
				subFields: [
					{
						name: 'headline',
						type: 'string',
						required: false,
						defaultValue: '',
					},
					{
						name: 'body',
						type: 'richText',
						required: false,
						defaultValue: '',
					},

					{
						name: 'linkAfterForm',
						type: 'object',
						required: false,
						subFields: [
							{
								name: 'title',
								helperText: 'The title of the link, used for accessibility',
								type: 'string',
								required: true,
							},
							{
								name: 'url',
								type: 'url',
								required: true,
							},
							{
								name: 'target',
								type: 'boolean',
								defaultValue: false,
								helperText: 'Toggle to open internal link in a new tab. External links will always open in a new tab.',
							},
						],
					},
				],
			},
			{
				name: 'thankYou',
				friendlyName: 'Thank You Message',
				type: 'object',
				subFields: [
					{
						name: 'headline',
						type: 'string',
					},
					{
						name: 'message',
						type: 'richText',
					},
				],
			},
			{
				name: 'centerDetails',
				type: 'object',
				required: false,
				subFields: [
					{
						name: 'title',
						type: 'text',
						helperText: 'Character limit: 20',
					},
					{
						name: 'centerAddressDetails',
						type: 'object',
						helperText: 'Address can be edited in Global Data.',
						subFields: [
							{
								name: 'googleMapLink',
								type: 'url',
							},
							{
								name: 'textBelowAddress',
								type: 'longText',
								defaultValue: 'Near the corner of Michigan & Wacker',
							},
						],
					},
					{
						name: 'riverCruiseDetails',
						type: 'object',
						helperText: 'Address can be edited in Global Data.',

						subFields: [
							{
								name: 'title',
								type: 'text',
							},
							{
								name: 'googleMapLink',
								type: 'url',
							},
						],
					},
					{
						name: 'mapImage',
						type: 'object',
						helperText: 'Include Google Maps image of where the center is located.',
						subFields: [
							{
								name: 'src',
								type: 'file',
								required: true,
							},
							{
								name: 'alt',
								type: 'string',
								required: true,
							},
						],
					},
				],
			},
			{
				name: 'subscriberSection',
				type: 'object',
				required: false,
				subFields: [
					{
						name: 'subscriberHeadline',
						type: 'string',
						required: false,
						defaultValue: 'Subscribe to Our Newsletter',
						helperText: 'The title of the link section',
					},
					{
						name: 'title',
						helperText: 'The button text of the link, used for accessibility',
						type: 'string',
						required: true,
					},
					{
						name: 'url',
						type: 'url',
						required: true,
					},
					{
						name: 'target',
						type: 'boolean',
						defaultValue: false,
						helperText: 'Toggle to open internal link in a new tab. External links will always open in a new tab.',
					},
				],
			},
		],
	};

	const carouselWithIconCards = {
		category: categories.content,
		component: resolveComponent('carouselWithIconCards'),
		name: 'Carousel with Icon Cards',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/carousel-with-icon-cards.png',
		inputs: [
			inputs.anchorId,
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'subHeadline',
				type: 'string',
				required: false,
			},
			{
				name: 'body',
				type: 'richText',
				required: false,
			},
			inputs.button,
			{
				name: 'cards',
				type: 'list',
				required: false,
				subFields: [
					inputs.image,
					{
						name: 'headline',
						type: 'string',
						required: false,
					},
					{
						name: 'body',
						type: 'richText',
						required: false,
					},
					inputs.button,
				],
			},
		],
	};

	const resourcesGrid = {
		category: categories.dynamic,
		component: resolveComponent('resourcesGrid'),
		name: 'Educator Resources Grid',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		// screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/additional-exhibitions.png',
		inputs: [inputs.anchorId],
	};

	const additionalExhibitions = {
		category: categories.content,
		component: resolveComponent('additionalExhibitions'),
		name: 'Additional Exhibitions',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/additional-exhibitions.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'subHeadline',
				type: 'string',
				required: false,
			},
			inputs.button,
			{
				name: 'automaticallyFetchPastExhibitions',
				type: 'boolean',
				defaultValue: true,
				helperText: 'Toggle this OFF if you prefer to manually choose Exhibitions below.',
			},
			{
				name: 'list',
				type: 'list',
				required: false,
				helperText: 'Choose TWO Exhibitions.',
				subFields: [
					{
						name: 'exhibition',
						type: 'reference',
						model: 'exhibitions',
					},
				],
			},
		],
	};

	const favoriteBuildings = {
		category: categories.content,
		component: resolveComponent('favoriteBuildings'),
		name: 'Favorite Buildings',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/favorite-buildings.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'building',
				type: 'reference',
				model: 'buildings',
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'quote',
				type: 'longText',
				required: true,
				defaultValue: 'Quote',
			},
			{
				name: 'attribution',
				type: 'text',
				required: false,
			},
			inputs.button,
		],
	};

	const factCarousel = {
		category: categories.content,
		component: resolveComponent('factCarousel'),
		name: 'Fact Carousel',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/fact-carousel.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'factList',
				type: 'list',
				required: false,
				subFields: [
					{
						name: 'title',
						type: 'text',
						required: false,
					},
					inputs.icon,
					{
						name: 'body',
						type: 'richText',
						required: false,
					},
				],
			},
		],
	};

	const buttonBlock = {
		category: categories.content,
		component: resolveComponent('buttonBlock'),
		name: 'Button Block',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/button-block.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'buttonList',
				type: 'list',
				required: false,
				subFields: [inputs.button],
			},
			{
				name: 'align',
				type: 'string',
				enum: ['Left', 'Center', 'Right'],
				defaultValue: 'Left',
			},
			{
				name: 'backgroundColor',
				type: 'string',
				enum: ['White', 'Gray'],
				defaultValue: 'White',
			},
		],
	};

	const architectureEncyclopedia = {
		category: categories.dynamic,
		component: resolveComponent('architectureEncyclopedia'),
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/architecture-encyclopedia.png',
		name: 'Architecture Encyclopedia',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
		],
	};

	const subNavQuickLinks = {
		category: categories.content,
		component: resolveComponent('subNavQuickLinks'),
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/sub-nav-quick-links.png',
		name: 'SubNav Quick Links',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'linkList',
				type: 'list',
				required: true,
				subFields: [inputs.button],
			},
		],
	};

	const formAirtable = {
		category: categories.cta,
		component: resolveComponent('formsAirtable'),
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		// screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/forms-airtable.png',
		name: 'Form (AirTable Embed)',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'code',
				type: 'html',
				code: true,
				required: true,
			},
		],
	};
	const storiesThreeUp = {
		category: categories.content,
		component: resolveComponent('storiesThreeUp'),
		name: 'Stories Three-Up',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/stories-three-up.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'body',
				type: 'richText',
				required: false,
			},
			inputs.button,
			{
				name: 'autoFetch',
				type: 'boolean',
				defaultValue: false,
				helperText: 'Toggle this ON to automatically fetch the 3 most recent Stories.',
			},
			{
				name: 'storyList',
				type: 'list',
				required: false,
				helperText: 'If the Auto Fetch is toggled OFF, manually input three stories below.',
				subFields: [
					inputs.image,
					{
						name: 'eyebrow',
						type: 'string',
						required: false,
					},
					{
						name: 'title',
						type: 'string',
						required: false,
					},
					{
						name: 'body',
						type: 'richText',
						required: false,
					},
					{
						name: 'link',
						type: 'object',
						subFields: [
							{
								name: 'url',
								type: 'url',
								required: false,
							},
							{
								name: 'target',
								type: 'boolean',
								defaultValue: 'false',
								helperText: 'Toggle to open internal link in a new tab. External links will always open in a new tab.',
							},
						],
					},
				],
			},
		],
	};

	const buildingsCarousel = {
		category: categories.content,
		component: resolveComponent('buildingsCarousel'),
		name: 'Buildings Carousel',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/buildings-carousel.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'body',
				type: 'richText',
				required: false,
			},
			inputs.button,
			{
				name: 'automaticallyFetchBuildings',
				type: 'boolean',
				defaultValue: true,
				helperText: 'Toggle this OFF if you prefer to manually choose Buildings below.',
			},
			{
				name: 'manualBuildingsList',
				type: 'list',
				subFields: [
					{
						name: 'building',
						type: 'reference',
						model: 'buildings',
					},
				],
			},
		],
	};

	const architectureEncyclopediaCallout = {
		category: categories.content,
		component: resolveComponent('architectureEncyclopediaCallout'),
		name: 'Encyclopedia Callout',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/architecture-encyclopedia-callout.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'copy',
				type: 'richText',
				required: false,
			},
			inputs.button,
			{
				name: 'automaticallyFetchArticles',
				type: 'boolean',
				defaultValue: true,
				helperText:
					'Leave this ON to automatically fetch 3 most recent articles. Toggle it OFF to manually choose articles to be displayed.',
			},
			{
				name: 'manualArticlesList',
				type: 'list',
				defaultValue: [],
				helperText:
					'This list will only populate on the front-end if the toggle above is set to OFF. ONLY CHOOSE THREE.',
				subFields: [
					{
						name: 'encyclopediaArticle',
						type: 'reference',
						model: 'architecture-encyclopedia-articles',
					},
				],
			},
		],
	};
	const statsFiftyFifty = {
		category: categories.content,
		component: resolveComponent('statsFiftyFifty'),
		name: 'Stats 50 50',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/stats-fifty-fifty.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'body',
				type: 'richText',
				required: false,
			},
			{
				name: 'statsList',
				type: 'list',
				required: false,
				subFields: [
					{
						name: 'prefix',
						type: 'string',
						required: false,
					},
					{
						name: 'stat',
						type: 'number',
						required: false,
					},
					{
						name: 'suffix',
						type: 'string',
						required: false,
					},
					{
						name: 'body',
						type: 'string',
						required: false,
					},
				],
			},
		],
	};

	const resourcesCarousel = {
		category: categories.dynamic,
		component: resolveComponent('resourcesCarousel'),
		name: 'Resources Carousel',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/resources-carousel.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
			{
				name: 'body',
				type: 'richText',
				required: false,
			},
			inputs.button,
			{
				name: 'automaticallyFetchPosts',
				type: 'boolean',
				defaultValue: true,
				helperText: 'Toggle this OFF if you prefer to manually choose Resources below.',
			},
			{
				name: 'manualResourceList',
				type: 'list',
				subFields: [
					{
						name: 'resource',
						type: 'reference',
						model: 'resources',
					},
				],
			},
		],
	};

	const subNavParentBlock = {
		category: categories.dynamic,
		name: 'Sub Nav Parent Block',
		component: resolveComponent('subNavParentBlock'),
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/info.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/sub-nav-parent-block.png',
		canHaveChildren: true,
		childRequirements: {
			message: 'You can only add Content Blocks as children for the Sub Nav.',
			// query: {
			// 	'component.category': {
			// 		$in: [
			// 			'Content Blocks',
			// 		],
			// 	},
			// },
		},
		inputs: [
			{
				name: 'removeBottomPadding',
				type: 'boolean',
				defaultValue: false,
				helperText: 'Toggle this ON to remove the bottom padding from the Sub Nav Parent.',
			},
			{
				name: 'navItems',
				type: 'list',
				subFields: [
					{
						name: 'title',
						type: 'string',
						defaultValue: 'Item Title',
					},
					{
						name: 'anchorTag',
						type: 'string',
						helperText:
							'Make sure the Anchor Link here matches the one you have added to the nested elements you want the link to scroll to.',
					},
				],
			},
		],
	};

	const missionVision = {
		category: categories.content,
		component: resolveComponent('missionVision'),
		name: 'Mission and Vision',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/mission-vision.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			inputs.image,
			{
				name: 'blackCard',
				type: 'object',
				required: false,
				subFields: [
					{
						name: 'title',
						type: 'string',
						required: false,
					},
					{
						name: 'copy',
						type: 'richText',
						required: false,
					},
				],
			},
			{
				name: 'whiteCard',
				type: 'object',
				required: false,
				subFields: [
					{
						name: 'title',
						type: 'string',
						required: false,
					},
					{
						name: 'copy',
						type: 'richText',
						required: false,
					},
				],
			},
		],
	};

	const awardsMarquee = {
		category: categories.dynamic,
		component: resolveComponent('awardsMarquee'),
		name: 'Awards Marquee',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/awards-marquee.png',
		inputs: [
			{
				name: 'headline',
				type: 'string',
				required: false,
			},
		],
	};

	const horizontalCards = {
		category: categories.content,
		component: resolveComponent('horizontalCards'),
		name: 'Horizontal Cards',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/horizontal-cards.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'cards',
				type: 'list',
				subFields: [
					{
						name: 'eyebrow',
						type: 'string',
						defaultValue: 'Text Above Title',
						required: false,
					},
					{
						name: 'title',
						type: 'string',
						defaultValue: 'Item Title',
						required: false,
					},
					{
						name: 'body',
						type: 'richText',
						defaultValue: 'Rich Text Area',
						required: false,
					},
					{
						name: 'addGrayBox',
						type: 'boolean',
						defaultValue: false,
					},
					{
						name: 'grayBoxTitle',
						type: 'string',
						required: false,
						showIf: (options: Map<string, any>) => options.get('addGrayBox') === true,
					},
					{
						name: 'grayBoxText',
						type: 'richText',
						required: false,
						showIf: (options: Map<string, any>) => options.get('addGrayBox') === true,
					},
				],
			},
			inputs.padding,
		],
	};

	const accordionWithImages = {
		category: categories.content,
		component: resolveComponent('accordionWithImages'),
		name: 'Accordion with Images',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/accordion-with-images.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'title',
				type: 'string',
				defaultValue: 'Accordion Title',
			},
			{
				name: 'items',
				type: 'list',
				subFields: [
					{
						name: 'title',
						type: 'string',
						defaultValue: 'Item Title',
					},
					inputs.image,
					{
						name: 'body',
						type: 'richText',
						defaultValue: 'Item Content',
					},
				],
			},
			inputs.button,
			inputs.padding,
		],
	};

	const imageCarouselWithCaptions = {
		category: categories.content,
		component: resolveComponent('imageCarouselWithCaptions'),
		name: 'Image Carousel with Captions',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/image-carousel-with-captions.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'title',
				type: 'string',
				required: false,
				defaultValue: '',
			},
			{
				name: 'imageList',
				type: 'list',
				subFields: [
					inputs.image,
					{
						name: 'caption',
						type: 'string',
						required: false,
					},
				],
			},
			inputs.padding,
		],
	};

	const text = {
		name: 'Text',
		component: resolveComponent('text'),
		override: true,
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'text',
				type: 'richText',
				required: true,
			},
			inputs.padding,
			{
				name: 'backgroundColor',
				type: 'string',
				defaultValue: 'bg-gray-100',
				enum: [
					{ label: 'Gray', value: 'bg-gray-100' },
					{ label: 'White', value: 'bg-white' },
				],
			},
		],
	};

	const customCode = {
		name: 'Custom Code',
		component: resolveComponent('customCode'),
		override: true,
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'code',
				type: 'html',
				code: true,
				required: true,
			},
		],
	};

	const programsEventsFilterWithGrid = {
		category: categories.dynamic,
		component: resolveComponent('programsEventsFilterWithGrid'),
		name: 'Programs and Events Filter with Grid',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/programs-events-filter-with-grid.png',
		inputs: [
			{
				name: 'featuredItem',
				type: 'reference',
				model: 'programs-events',
			},
		],
	};

	const fourUpStaggered = {
		category: categories.content,
		component: resolveComponent('fourUpStaggered'),
		name: 'Four Up - Staggered',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/four-up-staggered.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 30',
			},
			{
				name: 'verticalText',
				type: 'string',
				helperText: 'Character limit: 40',
			},
			{
				name: 'cards',
				type: 'list',
				helperText: 'Add four cards to complete the staggered grid, rotating clockwise from the top-right.',
				subFields: [
					inputs.image,
					{
						name: 'copy',
						type: 'richText',
					},
					{
						name: 'url',
						type: 'url',
						required: false,
					},
					{
						name: 'target',
						type: 'boolean',
						required: false,
						defaultValue: 'false',
						helperText: 'Toggle to open internal link in a new tab. External links will always open in a new tab.',
					},
				],
			},
		],
	};

	const upcomingEventsTwoUp = {
		category: categories.dynamic,
		component: resolveComponent('upcomingEventsTwoUp'),
		name: 'Upcoming Events Two Up',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/upcoming-events-two-up.png',
		inputs: [
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 30',
			},
			{
				name: 'copy',
				type: 'richText',
				helperText: 'Character limit: 160',
			},
			inputs.button,
			{
				name: 'events',
				type: 'list',
				helperText: 'Choose two Events or leave this blank and it will automatically fetch the two most recent Events.',
				subFields: [
					{
						name: 'eventOrProgram',
						type: 'reference',
						model: 'programs-events',
					},
				],
			},
		],
	};

	const fullWidthFiftyFiftyWithRedBanner = {
		category: categories.content,
		component: resolveComponent('fullWidthFiftyFiftyWithRedBanner'),
		name: 'Full Width Fifty-Fifty w Red Banner',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/full-width-fifty-fifty-with-red-banner.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'upperArea',
				type: 'object',
				subFields: [
					inputs.image,
					{
						name: 'card',
						type: 'object',
						subFields: [
							{
								name: 'title',
								type: 'string',
								defaultValue: 'Lorem ipsum',
								helperText: '',
							},
							{
								name: 'body',
								type: 'richText',
							},
							inputs.button,
						],
					},
					{
						name: 'cardAlignment',
						type: 'boolean',
						helperText: 'Leave this OFF to align the card to the left, turn ON to align the card to the right.',
					},
				],
			},
			{
				name: 'redBanner',
				type: 'object',
				subFields: [
					{
						name: 'headline',
						type: 'string',
						helperText: 'Character limit: 25',
						required: true,
					},
					{
						name: 'copy',
						type: 'longText',
					},
					{
						name: 'icon',
						type: 'object',
						subFields: [
							{
								name: 'src',
								type: 'file',
								required: true,
							},
							{
								name: 'alt',
								type: 'string',
								required: true,
							},
						],
					},
					{
						name: 'link',
						type: 'object',
						subFields: [
							{
								name: 'title',
								helperText: 'The title of the link, used for accessibility',
								type: 'string',
								required: true,
							},
							{
								name: 'url',
								type: 'url',
								required: true,
							},
						],
					},
				],
			},
		],
	};

	// NOTE: We shouldn't have split these blocks into separate components.

	// const featuredTour = {
	// 	category: categories.dynamic,
	// 	component: resolveComponent('featuredTour'),
	// 	name: 'Featured Tour',
	// 	image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
	// 	screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/featured-tour.png',
	// 	inputs: [],
	// };

	// const toursGrid = {
	// 	category: categories.dynamic,
	// 	component: resolveComponent('toursGrid'),
	// 	name: 'Tours Grid',
	// 	image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
	// 	screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/tours-grid.png',
	// 	inputs: [],
	// };

	const internalPageHero = {
		category: categories.hero,
		component: resolveComponent('internalPageHero'),
		name: 'Internal Page Hero',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/internal-page-hero.png',
		inputs: [
			inputs.image,
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 30',
			},
			{
				name: 'redCard',
				type: 'object',
				subFields: [
					{
						name: 'copy',
						type: 'richText',
						helperText: 'Character limit: 160',
					},
					inputs.button,
				],
			},
			{
				name: 'showAwards',
				type: 'boolean',
				defaultValue: true,
				helperText: 'Toggle this OFF if you want to hide awards. Otherwise, leave it ON.',
			},
			{
				name: 'awards',
				type: 'list',
				subFields: [
					{
						name: 'award',
						type: 'reference',
						model: 'awards-data',
					},
				],
			},
		],
	};

	const textOnlyHero = {
		category: categories.hero,
		component: resolveComponent('textOnlyHero'),
		name: 'Text Only Hero',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/marquee.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/internal-page-hero.png',
		inputs: [
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 30',
			},
			{
				name: 'backgroundColor',
				type: 'string',
				required: false,
				defaultValue: 'White',
				enum: [
					{ label: 'White', value: 'bg-white' },
					{ label: 'Gray', value: 'bg-gray-100' },
				],
			},
			inputs.padding,
		],
	};

	const fullWidthFiftyFifty = {
		category: categories.content,
		component: resolveComponent('fullWidthFiftyFifty'),
		name: 'Full Width 50 50',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/full-width-fifty-fifty.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			inputs.image,
			{
				name: 'card',
				type: 'object',
				subFields: [
					{
						name: 'title',
						type: 'string',
						defaultValue: 'Lorem ipsum',
						helperText: '',
					},
					{
						name: 'body',
						type: 'richText',
					},
					inputs.button,
				],
			},
			{
				name: 'cardAlignment',
				type: 'boolean',
				helperText: 'Leave this OFF to align the card to the left, turn ON to align the card to the right.',
			},
		],
	};

	const threeOrFourUpCardsWithLinks = {
		category: categories.content,
		component: resolveComponent('threeOrFourUpCardsWithLinks'),
		name: 'Three or Four Up Cards with Links',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/three-or-four-up-cards-with-links.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'cards',
				type: 'list',
				subFields: [
					{
						name: 'title',
						type: 'string',
						defaultValue: 'Item Title',
					},
					inputs.image,
					{
						name: 'url',
						type: 'url',
					},
					{
						name: 'target',
						type: 'boolean',
						defaultValue: 'false',
						helperText: 'Toggle to open internal link in a new tab. External links will always open in a new tab.',
					},
				],
			},
		],
	};

	const containerFiftyFifty = {
		category: categories.content,
		component: resolveComponent('containerFiftyFifty'),
		name: 'Container 50 50',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/container-fifty-fifty.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			inputs.image,
			{
				name: 'imageAlignment',
				type: 'boolean',
				defaultValue: false,
				required: false,
				helperText: 'Switch this ON to align the image to the right. Leave it OFF to align the image to the left.',
			},
			{
				name: 'headline',
				type: 'string',
				defaultValue: 'CAC Design Store',
				helperText: 'Character limit: 25',
			},
			{
				name: 'body',
				type: 'richText',
				defaultValue: 'Here is some rich text',
				helperText: 'Character limit: 160',
			},
			inputs.button,
		],
	};

	const twoUpWithText = {
		category: categories.content,
		component: resolveComponent('twoUpWithText'),
		name: '2Up w/ Text',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/two-up-with-text.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			inputs.image,
			{
				name: 'cards',
				type: 'list',
				onChange: (options: Map<string, any>) => {
					if (options.get('cards') && options.get('cards').length > 2) {
						options.set('cards', options.get('cards').slice(0, 2));
						alert('Maximum card items is 2');
					}
				},
				subFields: [
					{
						name: 'title',
						type: 'string',
						defaultValue: 'Item Title',
					},
					{
						name: 'body',
						type: 'richText',
						defaultValue: 'Item Content',
					},
					inputs.icon,
					inputs.button,
				],
			},
			{
				name: 'background',
				type: 'object',
				subFields: [
					{
						name: 'topColor',
						friendlyName: 'Top Color',
						type: 'text',
						enum: ['Gray', 'White'],
						defaultValue: 'White',
					},
					{
						name: 'bottomColor',
						friendlyName: 'Bottom Color',
						type: 'text',
						enum: ['Gray', 'White'],
						defaultValue: 'White',
					},
				],
			},
		],
	};

	const threeCardsWithLinks = {
		category: categories.content,
		component: resolveComponent('threeCardsWithLinks'),
		name: 'Three Cards w/ Links',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/three-cards-with-links.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				defaultValue: 'Exhibition Cards Headline',
			},
			inputs.button,
			{
				name: 'cards',
				type: 'list',
				subFields: [
					{
						name: 'title',
						type: 'string',
						defaultValue: 'Item Title',
					},
					inputs.image,
					{
						name: 'url',
						type: 'url',
					},
					{
						name: 'target',
						type: 'boolean',
						defaultValue: 'false',
						helperText: 'Toggle to open internal link in a new tab. External links will always open in a new tab.',
					},
				],
			},
		],
	};

	const exhibitionCards = {
		category: categories.content,
		component: resolveComponent('exhibitionCards'),
		name: 'Exhibition Cards',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/exhibition-cards.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'verticalText',
				type: 'string',
				defaultValue: 'Vertical Text',
			},
			{
				name: 'headline',
				type: 'string',
				defaultValue: 'Exhibitions',
			},
			{
				name: 'subHeadline',
				type: 'string',
				defaultValue: 'On View Now',
			},
			inputs.button,
			{
				name: 'automaticallyFetch',
				type: 'boolean',
				defaultValue: true,
				helperText: 'Leave this ON to automatically fetch two posts. Turn this OFF to manually choose them below.',
			},
			{
				name: 'cards',
				type: 'list',
				helperText: 'Choose TWO exhibitions.',
				subFields: [
					{
						name: 'exhibition',
						type: 'reference',
						model: 'exhibitions',
					},
				],
			},
		],
	};

	const accordion = {
		category: categories.content,
		component: resolveComponent('accordion'),
		name: 'Accordion',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accordion.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/accordion.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'title',
				type: 'string',
				defaultValue: 'Accordion Title',
			},
			{
				name: 'items',
				type: 'list',
				subFields: [
					{
						name: 'title',
						type: 'string',
						defaultValue: 'Item Title',
					},
					{
						name: 'body',
						type: 'richText',
						defaultValue: 'Item Content',
					},
				],
			},
		],
	};

	const headline = {
		category: categories.content,
		component: resolveComponent('headline'),
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/marquee.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/headline-block.png',
		name: 'Headline Block',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'title',
				type: 'string',
				defaultValue: 'Hello World!',
			},
		],
	};

	const hero = {
		category: categories.hero,
		component: resolveComponent('hero'),
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/marquee.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/hero.png',
		name: 'Hero',
		inputs: [
			{
				name: 'image',
				type: 'object',
				helperText: 'This image is required. When Video Embed is added, image will be used on mobile screen sizes.',
				required: true,
				subFields: [
					{
						name: 'src',
						type: 'file',
						bubble: true,
						allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
						required: true,
					},
					{
						name: 'alt',
						type: 'string',
						required: true,
					},
				],
			},
			{
				name: 'videoOptions',
				type: 'object',
				helperText: 'If a video embed is added, it will override any image file on Tablet and Desktop screen sizes.',
				subFields: [
					{
						name: 'embed',
						type: 'longText',
						required: false,
					},
					{
						name: 'addPlayFullVideoButton',
						type: 'boolean',
						required: false,
						helperText: 'Toggle this ON if you want to display a button to Play Full Video',
					},
				],
			},
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 40',
			},
			{
				name: 'copy',
				type: 'richText',
			},
			{
				name: 'verticalText',
				type: 'string',
				helperText: 'Character limit: 30',
			},
			inputs.button,
		],
	};

	const pullQuote = {
		category: categories.content,
		component: resolveComponent('pullQuote'),
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/marquee.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/pull-quote.png',
		name: 'Pull Quote',
		models: ['blog'],
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'quote',
				type: 'longText',
				defaultValue:
					'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit magna, semper quis malesuada a.',
			},
			{
				name: 'attribution',
				type: 'text',
				required: false,
				defaultValue: '',
			},
		],
	};

	const marquee = {
		category: categories.content,
		component: resolveComponent('marquee'),
		name: 'Marquee Text',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/marquee.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/marquee.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'text',
				type: 'string',
				required: true,
			},
			{
				name: 'scheduleMessage',
				type: 'boolean',
				defaultValue: false,
				required: false,
			},
			{
				name: 'scheduleMessageAt',
				type: 'date',
				showIf: (options: Map<string, any>) => options.get('scheduleMessage') === true,
			},
			{
				name: 'messageExpiresAt',
				type: 'date',
				showIf: (options: Map<string, any>) => options.get('scheduleMessage') === true,
			},
		],
	};

	const designStore = {
		category: categories.content,
		component: resolveComponent('designStore'),
		name: 'Design Store Block',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/design-store.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/design-store.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: true,
				defaultValue: 'Design Store',
			},
			{
				name: 'copy',
				type: 'richText',
			},
			inputs.button,
			{
				name: 'merchandise',
				type: 'list',
				onChange: (options: Map<string, any>) => {
					if (options.get('merchandise').length > 2) {
						options.set('merchandise', options.get('merchandise').slice(0, 2));
						alert('Maximum merchandise items is 2');
					}
				},
				subFields: [
					inputs.image,
					{
						name: 'title',
						type: 'string',
					},
					{
						name: 'price',
						type: 'number',
					},
					{
						name: 'memberPrice',
						type: 'number',
					},
					{
						name: 'linkToProduct',
						type: 'url',
						required: true,
					},
				],
			},
		],
	};

	const iconGrid = {
		category: categories.content,
		component: resolveComponent('iconGrid'),
		name: 'Icon Grid',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/icons.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/icon-grid.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: true,
			},
			{
				name: 'tag',
				type: 'string',
			},
			{
				name: 'icons',
				type: 'list',
				subFields: [
					{
						name: 'icon',
						type: 'object',
						subFields: [
							{
								name: 'src',
								type: 'file',
								required: true,
							},
							{
								name: 'alt',
								type: 'string',
								required: true,
							},
						],
					},
					{
						name: 'copy',
						type: 'longText',
					},
				],
				onChange: (options: Map<string, any>) => {
					if (options.get('icons').length > 6) {
						options.set('icons', options.get('icons').slice(0, 6));
						alert('Maximum number of icons is 6');
					}
				},
			},
			inputs.pullQuote,
		],
	};

	const featuredContent = {
		category: categories.content,
		component: resolveComponent('featuredContent'),
		name: 'Featured Content Block',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/stacked-images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/featured-content.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: true,
			},
			{
				name: 'tag',
				type: 'string',
			},
			{
				name: 'decorativeImage',
				type: 'object',
				subFields: [
					{
						name: 'src',
						type: 'file',
						required: true,
					},
					{
						name: 'alt',
						type: 'string',
						required: true,
					},
				],
			},
			{
				name: 'featuredImage',
				type: 'object',
				subFields: [
					{
						name: 'src',
						type: 'file',
						required: true,
					},
					{
						name: 'alt',
						type: 'string',
						required: true,
					},
				],
			},
			inputs.pullQuote,
		],
	};

	const eventsProgramsThreeUp = {
		category: categories.content,
		component: resolveComponent('eventsProgramsThreeUp'),
		name: 'Events & Programs Block',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/programs-events.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/events-programs-three-up.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: true,
			},
			inputs.button,
			{
				name: 'events',
				type: 'list',
				subFields: [
					{
						name: 'eventOrProgram',
						type: 'reference',
						model: 'programs-events',
					},
				],
			},
		],
	};

	const accessibilityCallout = {
		category: categories.content,
		component: resolveComponent('accessibilityCallout'),
		name: 'Accessibility Callout Block',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/accessibility.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/accessibility-callout.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 25',
				required: true,
			},
			{
				name: 'copy',
				type: 'longText',
			},
			{
				name: 'icon',
				type: 'object',
				subFields: [
					{
						name: 'src',
						type: 'file',
						required: true,
					},
					{
						name: 'alt',
						type: 'string',
						required: true,
					},
				],
			},
			{
				name: 'link',
				type: 'object',
				subFields: [
					{
						name: 'title',
						helperText: 'The title of the link, used for accessibility',
						type: 'string',
						required: true,
					},
					{
						name: 'url',
						type: 'url',
						required: true,
					},
				],
			},
		],
	};

	const fullWidthImageWithText = {
		category: categories.content,
		component: resolveComponent('fullWidthImageWithText'),
		name: 'Full Width Image with Text Block',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/full-width-image.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/full-width-image-with-text.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			inputs.image,
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 35',
				required: true,
			},
			inputs.pullQuote,
			{
				name: 'bottomPadding',
				friendlyName: 'Add Bottom Padding',
				type: 'boolean',
				required: false,
				defaultValue: false,
			},
		],
	};

	const centerDetailsLocationHours = {
		category: categories.child,
		component: resolveComponent('centerDetailsLocationHours'),
		name: 'Center Details - Location and Hours',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/clock.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/location-hours.png',
		inputs: [
			{
				name: 'title',
				type: 'text',
				helperText: 'Character limit: 20',
			},
			{
				name: 'centerAddressDetails',
				type: 'object',
				helperText: 'Address can be edited in Global Data.',
				subFields: [
					{
						name: 'link',
						type: 'url',
					},
					{
						name: 'directions',
						type: 'longText',
						defaultValue: '"Near the corner of Michigan & Wacker"',
					},
				],
			},
			{
				name: 'riverCruiseDetails',
				type: 'object',
				helperText: 'Address can be edited in Global Data.',

				subFields: [
					{
						name: 'title',
						type: 'text',
					},
					{
						name: 'link',
						type: 'url',
					},
				],
			},

			{
				name: 'mapImage',
				type: 'object',
				helperText: 'Include Google Maps image of where the center is located.',
				subFields: [
					{
						name: 'src',
						type: 'file',
						required: true,
					},
					{
						name: 'alt',
						type: 'string',
						required: true,
					},
				],
			},
		],
	};

	const centerDetailsAdmission = {
		category: categories.child,
		component: resolveComponent('centerDetailsAdmission'),
		name: 'Center Details - Admission',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/ticket.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/admission.png',
		inputs: [
			{
				name: 'title',
				type: 'text',
				helperText: 'Character limit: 20',
			},
			{
				name: 'copy',
				type: 'longText',
				helperText: 'Optional.',
			},
			{
				name: 'prices',
				type: 'list',
				subFields: [
					{
						name: 'ageGroup',
						type: 'text',
					},
					{
						name: 'price',
						type: 'text',
					},
				],
			},
			{
				name: 'specialAdmissionsText',
				helperText: 'Put any additional info regarding admission special offers in the rich text editor. Optional.',
				type: 'richText',
			},
			{
				name: 'specialAdmissionsLink',
				helperText: 'Add an additional link regarding admission special offers in the rich text editor. Optional.',
				type: 'object',
				subFields: [inputs.link],
			},
			inputs.button,
		],
	};

	const centerDetailsCenterMap = {
		category: categories.child,
		component: resolveComponent('centerDetailsCenterMap'),
		name: 'Center Details - Center Map',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/map.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/center-map.png',
		inputs: [
			{
				name: 'title',
				type: 'text',
				helperText: 'Character limit: 20',
			},
			{
				name: 'copy',
				type: 'longText',
				helperText: 'Optional.',
			},
			inputs.button,
			{
				name: 'centerMapImage',
				type: 'object',
				helperText: 'Include an image of the map of the center.',
				subFields: [
					{
						name: 'src',
						type: 'file',
						required: true,
					},
					{
						name: 'alt',
						type: 'string',
						required: true,
					},
				],
			},
		],
	};

	const toursFilter = {
		category: categories.dynamic,
		component: resolveComponent('toursFilter'),
		name: 'Tours Filter Block',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/filter.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/tours-filter.png',
		inputs: [
			{
				name: 'featuredTour',
				type: 'reference',
				model: 'tours',
				friendlyName: 'Featured Tour',
				helperText:
					'Choose a featured Tour to display at the top of the page. If left blank, no Tour will be featured.',
			},
		],
	};

	const centerDetailsParent = {
		category: categories.dynamic,
		name: 'Center Details Parent Block',
		component: resolveComponent('centerDetailsParent'),
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/info.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/center-details.png',
		canHaveChildren: true,
		defaultChildren: [
			{
				'@type': '@builder.io/sdk:Element',
				component: {
					name: 'Center Details - Location and Hours',
				},
			},
			{
				'@type': '@builder.io/sdk:Element',
				component: {
					name: 'Center Details - Admission',
				},
			},
			{
				'@type': '@builder.io/sdk:Element',
				component: {
					name: 'Center Details - Center Map',
				},
			},
		],
		childRequirements: {
			message: 'You can only put blocks related to Center Details in this block',
			query: {
				'component.name': {
					$in: ['Center Details - Location and Hours', 'Center Details - Admission', 'Center Details - Center Map'],
				},
			},
		},
	};

	const twoCards = {
		category: categories.content,
		component: resolveComponent('twoCards'),
		name: 'Two Cards',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/images.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/two-cards.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'cards',
				type: 'list',
				onChange: (options: Map<string, any>) => {
					if (options.get('cards').length > 2) {
						options.set('cards', options.get('cards').slice(0, 2));
						alert('Maximum card items is 2');
					}
				},
				subFields: [
					inputs.icon,
					{
						name: 'title',
						type: 'string',
						defaultValue: 'Item Title',
					},
					{
						name: 'body',
						type: 'richText',
						defaultValue: 'Lorem ipsum',
					},
					{
						name: 'useButton',
						type: 'boolean',
						defaultValue: false,
						helperText: 'Each card will default to an arrow link. Toggle this on if you prefer to use',
					},
					inputs.link,
					inputs.button,
				],
			},
			inputs.padding,
		],
	};

	const cityTours = {
		category: categories.content,
		component: resolveComponent('cityTours'),
		name: 'City Tours Carousel',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/city.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/city-tours.png',
		// We shouldn't have split these blocks into separate components.
		// There are a bunch of errors when I remove them from the block list though.
		// hideFromInsertMenu: true,
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 25',
				required: true,
				defaultValue: 'City Tours',
			},
			{
				name: 'copy',
				type: 'longText',
				defaultValue: 'By boat, foot or bus, we have over 85 tours across Chicago for you to choose from!',
			},
			inputs.button,

			{
				name: 'useDate',
				friendlyName: "Use today's date?",
				type: 'boolean',
				required: false,
				defaultValue: true,
				helperText:
					'If true, this block will display the tours that happen on the current day with the option to feature a tour. When false, this block will display any tours in the pinned tours list.',
				// helperText:
				//  'If false, this block will display the latest tours with the option to pin a tour. Only add a pinned tour if this field is toggled off.',
			},
			{
				name: 'subheading',
				friendlyName: 'Subheading',
				type: 'string',
				helperText: 'Add a subheading if block is not using date',
				required: false,
				defaultValue: "What's Next",
			},
			{
				name: 'pinnedTour',
				friendlyName: 'Featured Tour',
				type: 'reference',
				model: 'tours',
				helperText:
					"When using today's date, this tour will be pulled to the start of the carousel if the tour has departures today.",
			},
			{
				name: 'tours',
				friendlyName: 'Pinned Tours',
				helperText:
					"These tours will only show if this block is not using today's date.  This block will randomly backfill tours to ensure at least 3 tours are in the carousel.",
				type: 'list',
				subFields: [
					{
						name: 'tour',
						type: 'reference',
						model: 'tours',
					},
				],
			},
			{
				name: 'pinnedToursButtonLabel',
				friendlyName: 'Tours Button Label',
				type: 'string',
				helperText: 'Character limit: 20',
				default: 'tickets & times',
			},
		],
	};
	const manualCityTours = {
		category: categories.content,
		component: resolveComponent('cityToursManual'),
		name: 'manualCityTours',
		friendlyName: 'Manual City Tours',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/city.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/city-tours.png',
		// We shouldn't have split these blocks into separate components.
		// There are a bunch of errors when I remove them from the block list though.
		// hideFromInsertMenu: true,
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				helperText: 'Character limit: 25',
				required: true,
				defaultValue: 'Recommended Tours',
			},
			{
				name: 'buttonLabel',
				friendlyName: 'Button Label',
				type: 'string',
				helperText: 'Change the button label',
				required: false,
				defaultValue: 'View All Tours',
			},
			{
				name: 'buttonOverlap',
				type: 'boolean',
				defaultValue: true,
			},
			{
				name: 'tours',
				friendlyName: 'Pinned Tours',
				helperText: 'This block will randomly backfill tours to ensure at least 4 tours are shown.',
				type: 'list',
				subFields: [
					{
						name: 'tour',
						type: 'reference',
						model: 'tours',
					},
				],
			},
		],
	};

	//  const manualCityTours = {
	//   categories: categories.content,
	//   name: 'manualCityTours',
	//   component: resolveComponent('cityToursManual'),
	//   inputs: [
	//    {
	//     name: 'headline',
	//     type: 'string',
	//     required: true,
	//     defaultValue: 'Recommended Tours',
	//    },
	//    //  {
	//    //   name: 'buttonLabel',
	//    //   type: 'string',
	//    //   required: true,
	//    //   defaultValue: 'View All Tours',
	//    //   friendlyName: 'Button Label',
	//    //  },
	//    //  {
	//    //   name: 'tours',
	//    //   type: 'list',
	//    //   subFields: [
	//    //    {
	//    //     name: 'tour',
	//    //     type: 'reference',
	//    //     model: 'tours',
	//    //    },
	//    //   ],
	//    //  },
	//   ],
	//  };

	const pastExhibitionsGrid = {
		category: categories.dynamic,
		component: resolveComponent('pastExhibitionsGrid'),
		name: 'Exhibitions Archived Grid',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/grid.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/exhibitions-grid.png',
		inputs: [
			{
				name: 'featuredTour',
				type: 'reference',
				model: 'exhibitions',
			},
		],
	};

	const exhibitionsGrid = {
		category: categories.dynamic,
		component: resolveComponent('exhibitionsGrid'),
		name: 'Exhibitions Grid',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/grid.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/exhibitions-grid.png',
		inputs: [
			{
				name: 'featuredTour',
				type: 'reference',
				model: 'exhibitions',
			},
		],
	};

	const testimonialsCarousel = {
		category: categories.content,
		component: resolveComponent('testimonialsCarousel'),
		name: 'Testimonials Carousel',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/testimonials-carousel.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
				defaultValue: 'Headline',
			},
			{
				name: 'testimonials',
				type: 'list',
				subFields: [
					inputs.imageNotRequired,
					{
						name: 'quote',
						type: 'longText',
						required: true,
						defaultValue: 'Quote',
					},
					{
						name: 'attribution',
						type: 'string',
						required: false,
						defaultValue: 'Attribution',
					},
					{
						name: 'title',
						type: 'string',
						defaultValue: 'Title',
					},
				],
			},
		],
	};

	const video = {
		category: categories.media,
		component: resolveComponent('video'),
		name: 'Video',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/play.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/video.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'text',
				defaultValue: 'Headline for Video',
				required: false,
			},
			{
				name: 'videoEmbed',
				type: 'longText',
				helperText: 'Input Youtube or Vimeo embed strings.',
				defaultValue:
					'<iframe width="560" height="315" src="https://www.youtube.com/embed/VIpqCxNDNXE?si=dV4kba5fgOHgNkJa" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>',
			},
			{
				name: 'videoCaption',
				type: 'longText',
				required: false,
			},
		],
	};

	const buildingsFilter = {
		category: categories.dynamic,
		component: resolveComponent('buildingsFilter'),
		name: 'Buildings Filter Block',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/filter.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/buildings-filter.png',
		inputs: [
			{
				name: 'featuredBuilding',
				type: 'reference',
				model: 'buildings',
				friendlyName: 'Featured Building',
				helperText:
					'Choose a featured building to display at the top of the page. If left blank, no building will be featured.',
			},
		],
	};

	const docentsDetailList = {
		category: categories.content,
		component: resolveComponent('docentsDetailList'),
		name: 'Docents Detail List',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/users-rectangle.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/docents-detail-list.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
				defaultValue: 'Headline',
			},
			{
				name: 'docents',
				type: 'list',
				subFields: [
					inputs.image,
					{
						name: 'name',
						type: 'text',
						required: true,
						defaultValue: 'Name',
					},
					{
						name: 'docentClass',
						friendlyName: 'Docent Graduating Class',
						type: 'text',
						defaultValue: 'CAF Docent Class of 1999',
					},
				],
			},
		],
	};

	const boatAmenities = {
		category: categories.content,
		component: resolveComponent('boatAmenities'),
		name: 'Boat Amenities',
		image: 'https://cac-staging.vercel.thisismess.io/block-icons/boat.svg',
		screenshot: 'https://cac-staging.vercel.thisismess.io/block-screenshots/boat-amenities.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'leftCard',
				type: 'object',
				subFields: [
					{
						name: 'headline',
						type: 'string',
						required: true,
					},
					{
						name: 'icons',
						type: 'list',
						subFields: [
							{
								name: 'icon',
								type: 'object',
								subFields: [
									{
										name: 'src',
										type: 'file',
										required: true,
									},
									{
										name: 'alt',
										type: 'string',
										required: true,
									},
								],
							},
							{
								name: 'copy',
								type: 'longText',
							},
							{
								name: 'link',
								type: 'url',
								required: false,
							},
						],
						onChange: (options: Map<string, any>) => {
							if (options.get('icons').length > 6) {
								options.set('icons', options.get('icons').slice(0, 6));
								alert('Maximum number of icons is 6');
							}
						},
					},
				],
			},
			{
				name: 'rightCard',
				type: 'object',
				subFields: [
					inputs.image,
					{
						name: 'headline',
						type: 'string',
						required: true,
					},
					{
						name: 'copy',
						type: 'longText',
					},
				],
			},
		],
	};

	const contributorsCarousel = {
		category: categories.content,
		component: resolveComponent('contributorsCarousel'),
		name: 'Contributors Carousel',
		image: 'https://dev.architecture.org/block-icons/users-rectangle.svg',
		screenshot: 'https://dev.architecture.org/block-screenshots/contributors-carousel.png',
		inputs: [
			{
				name: 'anchorId',
				type: 'string',
				required: false,
			},
			{
				name: 'headline',
				type: 'string',
				required: false,
				defaultValue: 'Headline',
			},
			{
				name: 'useImages',
				friendlyName: 'Use Images?',
				type: 'boolean',
				required: false,
				defaultValue: false,
			},

			{
				name: 'contributors',
				type: 'list',
				subFields: [
					{
						name: 'useIcon',
						friendlyName: 'Add Icon Styles',
						type: 'boolean',
						required: false,
						defaultValue: true,
					},
					inputs.imageNotRequired,
					{
						name: 'name',
						type: 'text',
						required: true,
						defaultValue: 'Name',
					},
					{
						name: 'title',
						friendlyName: 'Title',
						type: 'text',
						defaultValue: 'Title',
					},
					inputs.link,
					{
						name: 'shortDescription',
						friendlyName: 'Short Description',
						type: 'longText',
						defaultValue: 'Short Description',
					},
				],
			},
		],
	};

	const blocks = [
		manualCityTours,
		newsletterBlock,
		announcementBlock,
		formAirtable,
		accordion,
		headline,
		hero,
		pullQuote,
		marquee,
		designStore,
		iconGrid,
		exhibitionCards,
		threeCardsWithLinks,
		featuredContent,
		eventsProgramsThreeUp,
		twoUpWithText,
		containerFiftyFifty,
		threeOrFourUpCardsWithLinks,
		accessibilityCallout,
		fullWidthFiftyFifty,
		internalPageHero,
		fullWidthImageWithText,
		centerDetailsParent,
		centerDetailsLocationHours,
		centerDetailsAdmission,
		centerDetailsCenterMap,
		docentsDetailList,
		membershipOptions,
		toursFilter,
		fullWidthFiftyFiftyWithRedBanner,
		upcomingEventsTwoUp,
		fourUpStaggered,
		twoCards,
		cityTours,
		pastExhibitionsGrid,
		exhibitionsGrid,
		programsEventsFilterWithGrid,
		customCode,
		imageCarouselWithCaptions,
		text,
		accordionWithImages,
		horizontalCards,
		awardsMarquee,
		missionVision,
		subNavParentBlock,
		resourcesCarousel,
		statsFiftyFifty,
		architectureEncyclopediaCallout,
		buildingsCarousel,
		storiesThreeUp,
		subNavQuickLinks,
		testimonialsCarousel,
		architectureEncyclopedia,
		buttonBlock,
		factCarousel,
		video,
		buildingsFilter,
		favoriteBuildings,
		additionalExhibitions,
		resourcesGrid,
		carouselWithIconCards,
		contactUsBlock,
		sponsorsMediaPartners,
		relatedTeamMembers,
		teamMembersFilterGrid,
		memberBenefits,
		docentProcess,
		alignedFiftyFifty,
		storiesGrid,
		largeIconsBlock,
		boatAmenities,
		contributorsCarousel,
		calendarGridWithFilters,
		textOnlyHero,
	] as Array<BuilderComponent>;

	registerBlocks(api, blocks);
	setCategories(api, blocks);

	return blocks;
};

const setCategories = (api: string, blocks: Array<BuilderComponent>) => {
	// if (process.client) return;

	builder.init(api);

	// https:github.com/BuilderIO/builder/blob/4f0dc79c1904a4133de6421056a02db8a493bed1/plugins/example-app-simple/src/plugin.ts#L5
	Builder.register('editor.settings', {
		customInsertMenu: true,
		hideFormComponents: true,
		hideDataTab: true,
		// hideSymbols: true,
		// hideTemplates: true,
		hideAnimateTab: true,
		hideVisualEditor: true,
		hideImportTab: true,
	});

	Object.values(categories).forEach((category) => {
		Builder.register('insertMenu', {
			name: category,
			items: blocks
				.filter((block) => block.category === category)
				.map((block) => {
					return {
						name: block.name,
					};
				}),
		});
	});
};

const registerBlocks = (api: string, blocks: Array<BuilderComponent>) => {
	builder.init(api);

	const frozenBlocks = JSON.parse(JSON.stringify(blocks)) as Array<BuilderComponent>;
	frozenBlocks.forEach((block) => {
		const component = block.component;
		delete block.component;
		delete block.category;
		Builder.registerComponent(component, block);
	});
};
